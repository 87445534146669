import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import {LAPTOP_MOBILE_POINTER} from "@tools/constants";
import {TypographyUI} from "@ui";

export const SectionTwoCt = () => (
    <SectionBoxSd id="twoSection">
        <TextBoxSd>
            <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
                Для кого мы разработали «Бизнес»
            </TypographyUI.H2>
            <TypographyUI.Body1Regular
                swichPoint={LAPTOP_MOBILE_POINTER}
                $c="var(--text-secondary)"
                $tac
            >
                Наш сервис выплат физлицам и самозанятым создан для компаний, которые ежедневно взаимодействуют с
                плательщиками налога на профессиональный доход. Мы не только берём на себя ваши рутинные задачи, но и
                консультируем, и обучаем ваших сотрудников
            </TypographyUI.Body1Regular>
        </TextBoxSd>
        <UlBoxSd>
            <LiBoxSd>
                <StaticImage
                    src="../../images/business/sectionTwo/Doc.png"
                    alt={"самозанятый"}
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={56}
                    height={56}
                />
                <TypographyUIBody1MediumSd>Подпишем договоры</TypographyUIBody1MediumSd>
            </LiBoxSd>

            <LiBoxSd>
                <StaticImage
                    src="../../images/business/sectionTwo/Receipt.png"
                    alt={"самозанятый"}
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={56}
                    height={56}
                />
                <TypographyUIBody1MediumSd>
                    Создадим счета и чеки
                </TypographyUIBody1MediumSd>
            </LiBoxSd>

            <LiBoxSd>
                <StaticImage
                    src="../../images/business/sectionTwo/CreditCard.png"
                    alt={"самозанятый"}
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={56}
                    height={56}
                />
                <TypographyUIBody1MediumSd>
                    Перечислим вознаграждение
                </TypographyUIBody1MediumSd>
            </LiBoxSd>

            <LiBoxSd>
                <StaticImage
                    src="../../images/business/sectionTwo/Success.png"
                    alt={"самозанятый"}
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={56}
                    height={56}
                />
                <TypographyUIBody1MediumSd>
                    Подготовим отчетность
                </TypographyUIBody1MediumSd>
            </LiBoxSd>
        </UlBoxSd>
    </SectionBoxSd>
);

const SectionBoxSd = styled.section`
    display: flex;
    flex-direction: column;
    transition: all 0.3s;
    flex-direction: column;
    align-items: center;
    padding-top: 120px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        padding-top: 80px;
    }
`;

const TextBoxSd = styled.div`
    max-width: 820px;
    width: 100%;
    margin-bottom: 56px;
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        margin-bottom: 40px;
    }
`;

const UlBoxSd = styled.ul`
    max-width: 1140px;
    width: 100%;
    background: var(--background-main-white);
    border-radius: 30px;

    margin: 0;

    display: flex;
    box-shadow: 0px 12px 30px rgba(97, 106, 136, 0.12);
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        padding: 48px;
        padding-bottom: 44px;
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        flex-direction: column;
        padding: 32px;
    }
`;

const LiBoxSd = styled.li`
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    list-style-type: none;

    & > * + * {
        margin-left: 24px;
    }

    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        /* flex-direction: column; */
        & + & {
            padding-left: 48px;
        }
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        & + & {
            margin-top: 48px;
        }
    }
`;

const TypographyUIBody1MediumSd = styled(TypographyUI.Body1Medium)`
    @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
        width: 144px;
        color: var(--text-secondary);
    }
    @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
        width: 100%;
    }
`;
