import React from "react";
import styled from "styled-components";

import p1D from "@images/partners/p1_d.svg";
import p1 from "@images/partners/p1.svg";
import p2D from "@images/partners/p2_d.svg";
import p2 from "@images/partners/p2.svg";
import p3D from "@images/partners/p3_d.svg";
import p3 from "@images/partners/p3.svg";
import p4D from "@images/partners/p4_d.svg";
import p4 from "@images/partners/p4.svg";
import p5D from "@images/partners/p5_d.svg";
import p5 from "@images/partners/p5.svg";
import p6D from "@images/partners/p6_d.svg";
import p6 from "@images/partners/p6.svg";

export const PartnersGriad = () => {
  return (
    <UlSd>
      <LiSd>
        <img src={p1D} className="disabled" alt="p1_d" />
        <img src={p1} className="normal" alt="p1" />
      </LiSd>
      <LiSd>
        <img src={p2D} className="disabled" alt="p2_d" />
        <img src={p2} className="normal" alt="p2" />
      </LiSd>
      <LiSd>
        <img src={p3D} className="disabled" alt="p3_d" />
        <img src={p3} className="normal" alt="p3" />
      </LiSd>
      <LiSd>
        <img src={p4D} className="disabled" alt="p4_d" />
        <img src={p4} className="normal" alt="p4" />
      </LiSd>
      <LiSd>
        <img src={p5D} className="disabled" alt="p5_d" />
        <img src={p5} className="normal" alt="p5" />
      </LiSd>
      <LiSd>
        <img src={p6D} className="disabled" alt="p6_d" />
        <img src={p6} className="normal" alt="p6" />
      </LiSd>
    </UlSd>
  );
};

const UlSd = styled.ul`
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: stretch;
  justify-items: center;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const LiSd = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 0 16px 8px 16px;

  .disabled {
    display: block;
  }
  .normal {
    display: none;
  }

  &:hover {
    .disabled {
      display: none;
    }
    .normal {
      display: block;
    }
  }

  & img {
    height: auto;
    width: 110px;
  }
`;
