import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { BackgroundPosterCt, RequestToJoinCt } from "@components";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI, ButtonUI, NoGapUI } from "@ui";
import { ButtonOfScrollCt } from "@components";
import { useWindowSize } from "@tools/hooks";
import { useSendEvent } from "@tools/hooks";

type TProps = {
  h1Text?: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  btnDisabled: boolean;
  handlerOfSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  isRequestSuccess: boolean | null;
  setIsRequestSuccess: React.Dispatch<React.SetStateAction<boolean | null>>;
  openModalWindow: boolean;
  setOpenModalWindow: React.Dispatch<React.SetStateAction<boolean>>;
};
export const SectionOfMainCt: React.FC<TProps> = ({
  h1Text,
  name,
  setName,
  phone,
  setPhone,
  email,
  setEmail,
  btnDisabled,
  handlerOfSubmitForm,
  isRequestSuccess,
  setIsRequestSuccess,
  openModalWindow,
  setOpenModalWindow,
}) => {
  const { width } = useWindowSize();
  const { tryBusiness } = useSendEvent();

  return (
    <SectionBoxSd>
      <BackgroundPosterCt />
      {width && width > LAPTOP_MOBILE_POINTER && (
        <BoxForImgBgSd>
          <StaticImage
            src="../../images/business/sectionOfMain/imgBg.png"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={649}
            height={451}
            layout="fixed"
            objectFit="contain"
            alt=""
          />
        </BoxForImgBgSd>
      )}

      <TextBoxSd>
        <TextOfTitleSd swichPoint={LAPTOP_MOBILE_POINTER}>
          {h1Text || (
              <>
                Упрощаем работу с<NoGapUI /> самозанятыми
              </>
            )}
        </TextOfTitleSd>
        <TextOfInfo swichPoint={LAPTOP_MOBILE_POINTER}>
          Все, что потребуется — добавить базу самозанятых и пополнить баланс личного кабинета. Остальное на нас
        </TextOfInfo>

        {
          // TODO?: Возможно временно вырубили (SWPS-213)
          /* <MarquizBtn onClick={clickBusiness}>
          <ButtonSd>Попробовать</ButtonSd>
        </MarquizBtn> */
        }

        {
          //TODO?  Возможно временно вырубили (PROJECT-143)
          /* <ButtonSd
          onClick={() => {
            setOpenModalWindow(true);
            clickBusiness();
          }}
        >
          Попробовать
        </ButtonSd> */
        }

        <ButtonSd onClick={() => {
          document.querySelector('#quizSection').scrollIntoView({behavior: 'smooth'});
        }}>Попробовать</ButtonSd>
      </TextBoxSd>
      <ButtonOfScrollSd id="twoSection" />

      <RequestToJoinCt
        name={name}
        setName={setName}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        btnDisabled={btnDisabled}
        handlerOfSubmitForm={handlerOfSubmitForm}
        isRequestSuccess={isRequestSuccess}
        setIsRequestSuccess={setIsRequestSuccess}
        openModalWindow={openModalWindow}
        setOpenModalWindow={setOpenModalWindow}
      />
    </SectionBoxSd>
  );
};

const SectionBoxSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  padding-top: 136px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-top: 40px;
  }
`;

const BoxForImgBgSd = styled.div`
  z-index: -1;
  position: absolute;
  top: 133px;
  left: 50%;
  margin-left: 27px;
  height: 451px;
  width: 649px;
  & > * {
    height: 100%;
    width: 100%;
  }
`;

const TextBoxSd = styled.div`
  width: 100%;
`;

const TextOfTitleSd = styled(TypographyUI.H1)`
  max-width: 490px;
  padding-bottom: 16px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 17px;
  }
`;

const TextOfInfo = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary);
  max-width: 524px;
  padding-bottom: 48px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 39px;
  }
`;

const ButtonSd = styled(ButtonUI)`
  width: 240px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 100%;
  }
`;

const ButtonOfScrollSd = styled(ButtonOfScrollCt)`
  width: 100%;
  margin-top: 151px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-top: 80px;
  }
`;
