import { graphql, useStaticQuery } from "gatsby";

export const useGetSectionThreeImgData =  () => useStaticQuery(graphql`
  {
    allFile(
      filter: {
        relativeDirectory: { eq: "images/business/sectionThree" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              formats: [AUTO, WEBP, AVIF]
              width: 490
              height: 350
            )
          }
        }
      }
    }
  }
`);
