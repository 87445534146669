import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { useWindowSize } from "@tools/hooks";

export const BlockOfBusinessPresentationCt = () => {
  const { width } = useWindowSize();
  return (
    <GridBoxSd>
      <LineBoxSd>
        <BoxOfImgSd>
          {width > LAPTOP_MOBILE_POINTER && (
            <StaticImage
              src="../../../../../images/business/sectionFour/cases.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={771}
              height={656}
              objectFit="contain"
              alt="background-img"
            />
          )}
          {width <= LAPTOP_MOBILE_POINTER && (
            <StaticImage
              src="../../../../../images/business/sectionFour/casesMb.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              layout="constrained"
              objectFit="contain"
              alt="background-img"
            />
          )}
        </BoxOfImgSd>
        <ContentBoxOfGridSd>
          <TextBoxOfGridSd>
            <TextOfTitleSd>Пользуйтесь платформой для выплат физлицам бесплатно</TextOfTitleSd>
            <TextOfInfo>
              Никаких скрытых платежей и абонентской платы. Возьмем только от 1% за денежные транзакции
            </TextOfInfo>
          </TextBoxOfGridSd>
          <TextBoxOfGridSd>
            <TextOfTitleSd>Получайте юридические и налоговые консультации</TextOfTitleSd>
            <TextOfInfo>
              У вас будет персональный менеджер, который всегда на связи. Он настроит все сервисы, поможет с документами и ответит на все вопросы о самозанятости
            </TextOfInfo>
          </TextBoxOfGridSd>
        </ContentBoxOfGridSd>
      </LineBoxSd>
    </GridBoxSd>
  );
};

const GridBoxSd = styled.div`
  max-width: 1142px;
  width: 100%;
  margin-top: 5px;
`;

const LineBoxSd = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-left: -142px;
    align-items: center;
    & + & {
      margin-top: 82px;
    }
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
  }
`;

const BoxOfImgSd = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    min-width: 736px;
    height: 550px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    width: 335px;
    height: 336px;
    margin: 0 auto;
    margin-top: 44px;
  }
`;

const ContentBoxOfGridSd = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    margin-left: 66px;
    width: 480px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    order: -1;
  }
`;

const TextBoxOfGridSd = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    & + & {
      margin-top: 64px;
    }
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    & + & {
      margin-top: 40px;
    }
  }
`;

const TextOfTitleSd = styled(TypographyUI.H3)`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 400px;
    margin-bottom: 12px;
  }
`;

const TextOfInfo = styled(TypographyUI.Body1Regular)`
  color: var(--text-secondary);
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 480px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    margin-top: 16px;
  }
`;
