import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import p1D from "@images/partners/p1_d.svg";
import p1 from "@images/partners/p1.svg";
import p2D from "@images/partners/p2_d.svg";
import p2 from "@images/partners/p2.svg";
import p3D from "@images/partners/p3_d.svg";
import p3 from "@images/partners/p3.svg";
import p4D from "@images/partners/p4_d.svg";
import p4 from "@images/partners/p4.svg";
import p5D from "@images/partners/p5_d.svg";
import p5 from "@images/partners/p5.svg";
import p6D from "@images/partners/p6_d.svg";
import p6 from "@images/partners/p6.svg";

export const PartnersSlider = () => {
  SwiperCore.use([Autoplay]);

  return (
    <SwiperBoxSd>
      <SwiperSd
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        loop={true}
        slidesPerView="auto"
        spaceBetween={80}
      >
        <SwiperSlide>
          <img src={p1D} className="disabled" alt="p1_d" />
          <img src={p1} className="normal" alt="p1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={p2D} className="disabled" alt="p2_d" />
          <img src={p2} className="normal" alt="p2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={p3D} className="disabled" alt="p3_d" />
          <img src={p3} className="normal" alt="p3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={p4D} className="disabled" alt="p4_d" />
          <img src={p4} className="normal" alt="p4" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={p5D} className="disabled" alt="p5_d" />
          <img src={p5} className="normal" alt="p5" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={p6D} className="disabled" alt="p6_d" />
          <img src={p6} className="normal" alt="p6" />
        </SwiperSlide>
      </SwiperSd>
    </SwiperBoxSd>
  );
};

const SwiperSd = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;

    .disabled {
      display: block;
    }
    .normal {
      display: none;
    }

    &:hover {
      .disabled {
        display: none;
      }
      .normal {
        display: block;
      }
    }
  }

  .swiper-slide img {
    height: 100%;
  }
`;

const SwiperBoxSd = styled.div`
  position: relative;
  min-width: 100vw;
  &::before {
    content: "";
    background: linear-gradient(
      90deg,
      #ffffff 31.96%,
      rgba(255, 255, 255, 0.265844) 55.4%,
      rgba(255, 255, 255, 0.441355) 72.44%,
      rgba(255, 255, 255, 0) 85.23%
    );
    width: 20%;
    height: 121px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }
  &::after {
    content: "";
    background: linear-gradient(
      270deg,
      #ffffff 31.96%,
      rgba(255, 255, 255, 0.265844) 55.4%,
      rgba(255, 255, 255, 0.441355) 72.44%,
      rgba(255, 255, 255, 0) 85.23%
    );
    width: 20%;
    height: 121px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
`;
