import React from "react";
import { Helmet } from "react-helmet";

import { Layout, useRequestToJoin } from "@components";
import {
  SectionOfMainCt,
  SectionTwoCt,
  SectionThreeCt,
  SectionFourCt,
  SectionQuizCt,
  PartnersSectionCt,
} from "@templates/massPayment";

const BusinessPage = () => {
  const {
    name,
    setName,
    phone,
    setPhone,
    email,
    setEmail,
    btnDisabled,
    handlerOfSubmitForm,
    isRequestSuccess,
    setIsRequestSuccess,
    openModalWindow,
    setOpenModalWindow,
  } = useRequestToJoin();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Массовые выплаты физ лицам на карты, сервис мгновенных выплат для юр лиц, масспеймент
        </title>
        <meta
          name="description"
          content="Массовые выплаты физическим лицам на банковские карты ➔ Сервис массовых выплат для юридических лиц ♛ Полная автоматизация выплат и налогов при работе с самозанятыми физлицами."
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/mass-payment"
        />
      </Helmet>

      {
        //TODO?: Возможно временно вырубили (SWPS-213)
        /* <MarquizInit /> */
      }

      <Layout>
        <SectionOfMainCt
          h1Text={"Настройте массовые выплаты самозанятым"}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          btnDisabled={btnDisabled}
          handlerOfSubmitForm={handlerOfSubmitForm}
          isRequestSuccess={isRequestSuccess}
          setIsRequestSuccess={setIsRequestSuccess}
          openModalWindow={openModalWindow}
          setOpenModalWindow={setOpenModalWindow}
        />
        <SectionTwoCt />
        <SectionThreeCt />
        <SectionFourCt />
        <PartnersSectionCt />
        <SectionQuizCt
          phone={phone}
          setPhone={setPhone}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          btnDisabled={btnDisabled}
          setOpenModalWindow={setOpenModalWindow}
          handlerOfSubmitForm={handlerOfSubmitForm}
          isRequestSuccess={isRequestSuccess}
        />
      </Layout>
    </>
  );
};

export default BusinessPage;
