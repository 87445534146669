type TData = {
  subtitle?: string;
  title?: string;
  infoText?: string;
  nameImg?: string;
  link?: string;
  textLink?: string;
}[];
export const DATA_ONE: TData = [
  {
    subtitle: "Моментальные выплаты",
    title: "Выплаты самозанятым без проблем",
    infoText:
      "Благодаря официальному статусу партнера ФНС выплаты физлицам на карты пройдут моментально и без блокировок",
    nameImg: "Picture",
  },
  {
    subtitle: "Расчет и уплата налогов",
    title: "Работа с приложением «Мой налог»",
    infoText:
      "Наш сервис выплат физлицам и самозанятым сам рассчитывает и уплачивает налог. Исполнителю не нужно самостоятельно вносить информацию в приложение",
    nameImg: "Picture-1",
  },
  {
    subtitle: "Документооборот",
    title: "Электронный документооборот",
    infoText:
      "За вас создадим и подпишем договор ГПХ с новыми самозанятыми, а ещё подготовим все закрывающие документы для налоговой отчетности",
    nameImg: "Picture-2",
  },
  {
    subtitle: "Оформление персонала",
    title: "Официальная работа",
    infoText:
      "Проверим статус и лимит самозанятого перед выплатой и проконтролируем, что все налоги уплачены вовремя. Если вы работаете с иностранцами, поможем подать заявление в МВД и напомним о продлении миграционных документов",
    nameImg: "Picture-3",
    link: "/check-inn",
    textLink: "Проверить статус",
  },
  {
    subtitle: "Маркетплейс",
    title: "Биржа самозанятых",
    infoText:
      "На маркетплейсе вы найдете любого необходимого специалиста, зарегистрированного в ФНС в качестве плательщика налога на профессиональный доход",
    nameImg: "Picture-4",
    link: "https://market.selfwork.ru/",
  },
];

export const DATA_TWO: TData = [
  // {
  //   title: "Обеспечим прием платежей и выплаты физическим лицам на вашем сайте",
  //   infoText:
  //     "Наша инфраструктура сертифицирована по PCI DSS SAQ-D for Service Providers. Наш сервис позволит настроить массовые выплаты для физических лиц и для самозанятых граждан, а также настроить прием платежей",
  //   nameImg: "Picture-5",
  // },
  {
    title: "Работаем с агрегаторами и маркетплейсами по агентской схеме",
    infoText:
      "Наладим выдачу чеков, если самозанятые сотрудники оказывают услуги не вам, а вашим клиентам. Подойдет службам доставки и такси",
    nameImg: "Picture-6",
  },
  {
    title:
      "Автоматизируем работу иностранных компаний с самозанятыми из России",
    infoText:
      "У иностранных компаний возникают проблемы с массовыми выплатами самозанятым через российские банки. Разберемся в вопросе и настроим расчеты с сотрудниками из РФ",
    nameImg: "Picture-7",
  },
  {
    title: "Консультируем и интегрируем",
    infoText:
      "Расскажем, как стать партнером ФНС и поможем автоматизировать работу с самозанятыми с помощью API-интеграции",
    nameImg: "Picture-8",
  },
];
