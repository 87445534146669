import React from "react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image";
import { CaseCt } from "../atoms";
import { Link } from "gatsby";
import { LinkUI } from "@ui";

export const CasesOfMobile = () => (
  <CasesBoxSd>
    <CardSd>
      <CaseCt
        title="Аутсорсинговая компания"
        text={
          <>
            В начале года мы поставили перед собой цель — улучшить
            взаимодействие с персоналом и за счет этого увеличить эффективность
            всей компании. Для этого наша команда хотела настроить
            взаимодействие с самозанятыми, но у нас не было ресурсов для того,
            чтобы напоминать сотням внештатных сотрудников вовремя создавать и
            отправлять чеки. Да и проверять вручную статусы самозанятых перед
            выплатами мы не могли. <br />
            <br />
            Самозанятые.рф предложили именно то, что было нужно. Без
            автоматизации процессов мы бы не достигли такого результата
          </>
        }
        logoNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/logoPng/lps-outsourcing.png"
            alt="lps-outsourcing"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={122}
            height={50}
          />
        }
        posterNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/posterPng/lps-outsourcing.png"
            alt="lps-outsourcing"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={414}
            height={414}
          />
        }
      />
    </CardSd>
    <CardSd>
      <CaseCt
        title="Онлайн-маркетинг"
        text={
          <>
            Раньше нам не хватало внутренних ресурсов для реализации новых
            задач. Организация документооборота, контроль статуса самозанятых,
            ручной сбор чеков — все это отнимало много времени.
            <br />
            <br />С помощью сервиса{" "}
            <Link to="/">
              <LinkUI.NoLink>Самозанятые.рф</LinkUI.NoLink>
            </Link>{" "}
            мы автоматизировали работу с самозанятыми и поэтому смогли
            существенно масштабировать наше сотрудничество с внештатными
            исполнителями. А еще служба поддержки всегда ответит на любые
            вопросы о налоге на профессиональный доход.
          </>
        }
        logoNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/logoPng/ingate.png"
            alt="ingate"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={122}
            height={50}
          />
        }
        posterNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/posterPng/ingate.png"
            alt="lps-outsourcing"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={414}
            height={414}
          />
        }
      />
    </CardSd>

    <CardSd>
      <CaseCt
        title="Сервис дистрибуции контента в социальных сетях"
        text={
          <>
            Главная задача нашей платформы — рассказывать о товарах и услугах
            брендов через контент, размещенный в тематических сообществах.
            Клиентам важно разнообразие, поэтому мы постоянно расширяем круг
            исполнителей. Чтобы ускорить процесс привлечения владельцев
            сообществ, мы обратились к Самозанятые.рф для автоматизации
            взаиморасчетов. <br />
            <br />
            Нас привлекла возможность интеграции по API — мы хотели, чтобы весь
            необходимый функционал был доступен пользователям в личном кабинете
            BidFox. Команда Самозанятых настроила сервис у нас на площадке, и
            теперь мы экономим не только время, но и деньги!
          </>
        }
        logoNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/logoPng/bidfox.png"
            alt="bidfox"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={122}
            height={50}
          />
        }
        posterNode={
          <StaticImage
            src="../../../../../images/business/sectionFour/posterPng/bidfox.png"
            alt="bidfox"
            loading="eager"
            placeholder="none"
            formats={["auto", "webp"]}
            width={414}
            height={414}
          />
        }
      />
    </CardSd>
  </CasesBoxSd>
);

const CardSd = styled.div``;

const CasesBoxSd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
