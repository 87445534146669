import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import { StaticImage } from "gatsby-plugin-image";
import { CaseCt } from "../atoms";
import { Link } from "gatsby";
import { LinkUI } from "@ui";

export const CasesOfDesktop = () => {
  SwiperCore.use([Autoplay, Pagination]);

  const pagination = {
    clickable: true,
    renderBullet: (id, className) =>
      `<div class="custome-pagination ${className}"></div>`,
  };

  return (
    <div>
      <SwiperSd
        slidesPerView={"auto"}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        spaceBetween={40}
        initialSlide={1}
        pagination={pagination}
        loop={true}
      >
        <SwiperSlide>
          <BoxOfContentSlideSd>
            <CardSd>
              <CaseCt
                title="Аутсорсинговая компания"
                text={
                  <>
                    В начале года мы поставили перед собой цель — улучшить
                    взаимодействие с персоналом и за счет этого увеличить
                    эффективность всей компании. Для этого наша команда хотела
                    настроить взаимодействие с самозанятыми, но у нас не было
                    ресурсов для того, чтобы напоминать сотням внештатных
                    сотрудников вовремя создавать и отправлять чеки. Да и
                    проверять вручную статусы самозанятых перед выплатами мы не
                    могли. <br />
                    <br />
                    Самозанятые.рф предложили именно то, что было нужно. Без
                    автоматизации процессов мы бы не достигли такого результата
                  </>
                }
                logoNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/logoPng/lps-outsourcing.png"
                    alt="lps-outsourcing"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={122}
                    height={50}
                  />
                }
                posterNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/posterPng/lps-outsourcing.png"
                    alt="lps-outsourcing"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={414}
                    height={414}
                  />
                }
              />
            </CardSd>
          </BoxOfContentSlideSd>
        </SwiperSlide>
        <SwiperSlide>
          <BoxOfContentSlideSd>
            <CardSd>
              <CaseCt
                title="Онлайн-маркетинг"
                text={
                  <>
                    Раньше нам не хватало внутренних ресурсов для реализации
                    новых задач. Организация документооборота, контроль статуса
                    самозанятых, ручной сбор чеков — все это отнимало много
                    времени.
                    <br />
                    <br />С помощью сервиса{" "}
                    <Link to="/">
                      <LinkUI.NoLink>Самозанятые.рф</LinkUI.NoLink>
                    </Link>{" "}
                    мы автоматизировали работу с самозанятыми и поэтому смогли
                    существенно масштабировать наше сотрудничество с внештатными
                    исполнителями. А еще служба поддержки всегда ответит на
                    любые вопросы о налоге на профессиональный доход.
                  </>
                }
                logoNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/logoPng/ingate.png"
                    alt="ingate"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={122}
                    height={50}
                  />
                }
                posterNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/posterPng/ingate.png"
                    alt="lps-outsourcing"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={414}
                    height={414}
                  />
                }
              />
            </CardSd>
          </BoxOfContentSlideSd>
        </SwiperSlide>
        <SwiperSlide>
          <BoxOfContentSlideSd>
            <CardSd>
              <CaseCt
                title="Сервис дистрибуции контента в социальных сетях"
                text={
                  <>
                    Главная задача нашей платформы — рассказывать о товарах и
                    услугах брендов через контент, размещенный в тематических
                    сообществах. Клиентам важно разнообразие, поэтому мы
                    постоянно расширяем круг исполнителей. Чтобы ускорить
                    процесс привлечения владельцев сообществ, мы обратились к
                    Самозанятые.рф для автоматизации взаиморасчетов. <br />
                    <br />
                    Нас привлекла возможность интеграции по API — мы хотели,
                    чтобы весь необходимый функционал был доступен пользователям
                    в личном кабинете BidFox. Команда Самозанятых настроила
                    сервис у нас на площадке, и теперь мы экономим не только
                    время, но и деньги!
                  </>
                }
                logoNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/logoPng/bidfox.png"
                    alt="bidfox"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={122}
                    height={50}
                  />
                }
                posterNode={
                  <StaticImage
                    src="../../../../../images/business/sectionFour/posterPng/bidfox.png"
                    alt="bidfox"
                    loading="eager"
                    placeholder="none"
                    formats={["auto", "webp"]}
                    width={414}
                    height={414}
                  />
                }
              />
            </CardSd>
          </BoxOfContentSlideSd>
        </SwiperSlide>
      </SwiperSd>
    </div>
  );
};

const SwiperSd = styled(Swiper)`
  width: 100%;
  height: 100%;
  .swiper-slide {
    width: 1140px;
    margin-bottom: 52px;
  }
  .swiper-pagination {
    padding-top: 24px;
    .custome-pagination {
      background: #b0b5c4;
      border-radius: 5px;
      height: 3px;
      width: 22px;
      & + & {
        margin-left: 20px;
      }
    }
  }
`;
const BoxOfContentSlideSd = styled.div`
  height: 500px;
  padding-top: 16px;
`;

const CardSd = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(97, 106, 136, 0.12);
  border-radius: 30px;
  width: 1140px;
  height: 480px;
  padding: 48px 50px;
`;

const GridBoxSd = styled.div``;

const TitleBlockSd = styled.div``;

const VerticalLineSd = styled.div``;
