import React, { useMemo } from "react";
import styled from "styled-components";

import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { TypographyUI, ButtonUI, LinkUI } from "@ui";
import { DATA_ONE, DATA_TWO } from "./constants";
import { useGetSectionThreeImgData } from "./hooks";
import { getImage } from "gatsby-plugin-image";
import { ChessGridCt } from "@components";

export const SectionThreeCt = () => {
  const imgsData = useGetSectionThreeImgData();
  const contentListOne = useMemo(
    () =>
      DATA_ONE.map(
        ({ subtitle, title, infoText, nameImg, link, textLink }) => ({
          imgData: getImage(filterOfImgData(nameImg, imgsData).node),
          subtitle,
          title,
          infoText,
          link,
          textLink,
        })
      ),
    [imgsData]
  );
  const contentListTwo = useMemo(
    () =>
      DATA_TWO.map(
        ({ subtitle, title, infoText, nameImg, link, textLink }) => ({
          imgData: getImage(filterOfImgData(nameImg, imgsData).node),
          subtitle,
          title,
          infoText,
          link,
          textLink,
        })
      ),
    [imgsData]
  );

  return (
    <>
      <SectionSd>
        <TextBoxSd>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
              Как мы облегчаем работу с самозанятыми
          </TypographyUI.H2>
        </TextBoxSd>
        <GroupLineBoxSd>
          <ChessGridCt contentList={contentListOne} />
        </GroupLineBoxSd>
      </SectionSd>

      <SectionSd>
        <TextBoxSd>
          <TypographyUI.H2 swichPoint={LAPTOP_MOBILE_POINTER} $mb="12px" $tac>
            Учтем особенности вашего бизнеса
          </TypographyUI.H2>
        </TextBoxSd>
        <GroupLineBoxSd>
          <ChessGridCt contentList={contentListTwo} />
        </GroupLineBoxSd>
      </SectionSd>
    </>
  );
};

const filterOfImgData = (name, data) =>
  data.allFile.edges.filter((i) => i?.node?.name === name)[0];

const SectionSd = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  & + & {
    padding-top: 0;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 200px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 120px;
  }
`;

const TextBoxSd = styled.div`
  max-width: 820px;
  width: 100%;
  padding-bottom: 48px;
`;

const GroupLineBoxSd = styled.div`
  max-width: 1142px;
  width: 100%;
`;

const ImgBoxSd = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    width: 490px;
    height: 350px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 100%;
    order: -1;
    margin-bottom: 40px;
    width: 334px;
    height: 250px;
  }
`;
