import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { LineBreakUI, TypographyUI } from "@ui";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

// import { SRC_SVG_LOGO } from "../../constants";

type TProps = {
  title: string;
  text: string | React.ReactElement;
  logoNode: React.ReactNode;
  posterNode: React.ReactNode;
};
export const CaseCt: React.FC<TProps> = ({
  title,
  text,
  logoNode,
  posterNode,
}) => {
  return (
    <BoxSd>
      <div>
        <TitleBlockSd>
          <LogoBlockSd>{logoNode}</LogoBlockSd>
          <VerticalLineSd />
          <TitleSd>{title}</TitleSd>
        </TitleBlockSd>
        <TypographyUI.Body1Regular>{text}</TypographyUI.Body1Regular>
      </div>

      <PosterBlockSd>{posterNode}</PosterBlockSd>
    </BoxSd>
  );
};

const BoxSd = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    & > * + * {
      margin-left: 77px;
    }
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: column;
    margin-bottom: 95px;
  }
`;

const LogoBlockSd = styled.div`
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 76px;
    width: 100%;
  }
`;

const TitleBlockSd = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    flex-direction: row-reverse;
    margin-bottom: 20px;
    justify-content: space-between;
  }
`;

const TitleSd = styled(TypographyUI.Body1Medium)`
  color: var(--text-placeholder);
  max-width: 248px;
  width: 100%;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 243px;
  }
`;

const VerticalLineSd = styled.div`
  margin-left: 20px;
  margin-right: 32px;
  height: 44px;
  width: 1px;
  background: var(--text-placeholder);
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    display: none;
  }
`;

const LineBreakSd = styled(LineBreakUI)`
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    /* max-width: 335px; */
  }
  & + & {
    margin-top: 20px;
  }
`;

const PosterBlockSd = styled.div`
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    min-width: 414px;
    min-height: 414px;
  }
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    max-width: 335px;
    max-height: 335px;
    width: 100%;
    height: 100%;
  }
`;
